.about--us {
  position: relative;
  // padding: 2rem 0;

  &__overview {
    padding: 2rem 0;
    &--header {
      margin-bottom: 1.5rem;
      h2 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      p {
        font-weight: 300;
        color: #1d2a35;
        @media (min-width: 426px) {
          margin-right: 2rem;
        }
      }
    }
    &--card {
      margin-bottom: 1.5rem;
      background: #ffffff;
      box-shadow: 0px 4px 18px -3px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      @media (min-width: 426px) {
        padding: 2rem;
        width: 25rem;
      }
      max-width: 95%;
      padding: 1.5rem 1rem;

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      &_list {
        list-style: disc;
        @media (min-width: 426px) {
          padding-left: 2rem;
        }
        padding-left: 1rem;
        &-item {
          font-size: 0.9rem;
          margin-bottom: 0.5rem;
          font-weight: 600;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    &--footer {
      margin-bottom: 1.5rem;
      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      ul {
        li {
          padding-bottom: 0.5rem;
          small {
            margin-left: 1rem;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .fixed-column {
      position: sticky;
      left: 0;
      right: 0;
      top: 120px;
      height: 700px;
    }

    .scrolling-column {
      float: left;
    }
  }

  &__detail {
    padding: 2rem 0;
    &--list {
      &-item {
        margin-bottom: 2.5rem;
        h4 {
          font-size: 1.2rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }
        p {
          color: #1d2a35;
        }

        figure {
          img {
            width: 100%;
            height: auto;
            border-radius: 16px;
          }
        }
      }
    }
  }
}

.service--button {
  position: fixed;
  right: 1rem;
  bottom: 10rem;
  border-radius: 12px;
  box-shadow: 0px 4px 18px -3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem;
  font-weight: 600;
  @media (min-width: '768px') {
    display: none;
  }
  span {
    font-size: 0.9rem;
  }
}

.not-visible {
  position: fixed;
  bottom: 5rem;
  right: 3rem;
  display: none;
  transition: visibility 0.5s ease-in-out;
  z-index: 99;
}

.service--card {
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__modal {
    position: fixed;
    top: 15vh;
    @media (min-width: 426px) {
      left: calc(50% - 12.5rem);
      width: 25rem;
    }
    left: 2.5%;
    width: 95%;
    animation: slide-up 300ms ease-out forwards;
    z-index: 30;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(12rem);
  }
  to {
    transform: translateY(0);
  }
}
