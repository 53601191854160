.layoutTitle {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.productCard {
  position: relative;
  margin-bottom: 1rem;
  border: $border;
  border-radius: $radius;
  padding: 1rem;
  &-image {
    height: 15rem;
    // padding: 1.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-title {
    color: color(text-dark);
    font-weight: 400 !important;
    height: 2.9rem;
    overflow: hidden;
    @include truncate;
    -webkit-line-clamp: 2;
  }
  &-price {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .priceValue {
      font-weight: 500;
      font-size: 20px;
      color: color(text-dark);
    }
    .stockValue {
      color: color(success, base);
      background: color(success, tint);
      font-size: 14px;
      font-weight: 500;
      padding: 5px;
      border-radius: $radius;
    }
  }

  .cardOff {
    background: color(primary, base);
    position: absolute;
    top: 5%;
    left: 5%;
    padding: 0.1rem;
    font-size: 12px;
    color: white;
  }
}

.adsWrapper {
  margin: 5rem 0;
  &-image {
    img {
      width: 100%;
    }
  }
}

//clean

.homeCleanWrapper {
  margin-top: 5rem;
  &-title {
    margin-bottom: 2rem;
  }
  &-box {
    width: 80%;
    text-align: justify;
    @include responsive-for(sm) {
      width: 100%;
    }
  }
  &-image {
    img {
      width: 100%;
    }
  }
}

.myOrderTable {
  @include responsive-for(md) {
    display: none;
  }
}

.loginError {
  background: color(danger, tint);
  margin-top: 1rem;
  padding: 5px 1rem;
  border-radius: $radius;
  color: color(danger, base);
  width: 100%;
  text-align: center;
}

.socialLogin {
  display: flex;
  @include responsive-for(md) {
    display: block;
  }
}
